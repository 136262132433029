import { Capture, getBounds, OSKGeoJson, Sensor, SigmaAPI } from 'oskcore';
import { AppDispatch, RootState } from '../../store';
import { filter, flatMap } from 'lodash';
import { FootprintEntry, setFootprints } from './map';
import { add, format } from 'date-fns';
import { getProgramId } from '~/utils';
import { createSelector } from '@reduxjs/toolkit';
import { enqueueFilesById } from './cart';
import L from 'leaflet';
import { bucketByDateNoGaps } from '~/molecules/map/TimelineSlider/bucket';

export type TimelineMode = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type NullableDate = Date | null;

export type EnviHeaders = { [id: string]: any };

/**
 * Manual - Set by the user
 * Auto - Generated by code
 */
export type AoiType = 'manual' | 'auto' | 'viewport';

const SET_SEARCHING = 'SET_SEARCHING';
export function setSearching(isSearching: boolean) {
    return {
        type: SET_SEARCHING,
        payload: {
            isSearching,
        },
    };
}

const SET_ROI = 'SET_ROI';
export function setRoi(geoJson?: OSKGeoJson, aoiName?: string, aoiType?: AoiType) {
    return {
        type: SET_ROI,
        payload: {
            geoJson,
            aoiName,
            aoiType,
        },
    };
}

const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT';
export function updateSearchResults(result?: Array<Capture>, retainTaskIds?: string[]) {
    return {
        type: UPDATE_SEARCH_RESULT,
        payload: {
            result,
            retainTaskIds,
        },
    };
}

const UPDATE_API_RESULTS_LENGTH = 'UPDATE_API_RESULTS_LENGTH';
export function updateApiResultsLength(length: number) {
    return {
        type: UPDATE_API_RESULTS_LENGTH,
        payload: {
            length,
        },
    };
}

const INCLUDE_PLATFORM = 'INCLUDE_PLATFORM';
export function includePlatform(platform: number) {
    return {
        type: INCLUDE_PLATFORM,
        payload: {
            platform,
        },
    };
}

const EXCLUDE_PLATFORM = 'EXCLUDE_PLATFORM';
export function excludePlatform(platform: number) {
    return {
        type: EXCLUDE_PLATFORM,
        payload: {
            platform,
        },
    };
}

const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR';
export function setSearchError(errorMessage?: string) {
    return {
        type: SET_SEARCH_ERROR,
        payload: {
            errorMessage,
        },
    };
}

const SET_DATE_RANGE = 'SET_DATE_RANGE';
export function setDateRange(start: NullableDate, end: NullableDate) {
    return {
        type: SET_DATE_RANGE,
        payload: {
            start,
            end,
        },
    };
}

const SET_CLOUD_COVER_FILTER = 'SET_CLOUD_COVER_FILTER';
export function setCloudCoverFilter(cloudCover: number) {
    return {
        type: SET_CLOUD_COVER_FILTER,
        payload: { cloudCover },
    };
}

const CLEAR_SEARCH_ERROR = 'CLEAR_SEARCH_ERROR';
export function clearSearchError() {
    return {
        type: CLEAR_SEARCH_ERROR,
    };
}

const SET_TIMELINE_MODE = 'SET_TIMELINE_MODE';
export function setTimelineMode(mode: TimelineMode) {
    return {
        type: SET_TIMELINE_MODE,
        payload: {
            mode,
        },
    };
}

const SET_TIMELINE_DATE = 'SET_TIMELINE_DATE';
export function setTimelineDate(date: NullableDate) {
    return {
        type: SET_TIMELINE_DATE,
        payload: {
            date,
        },
    };
}

const TOGGLE_SEARCH_PANEL = 'TOGGLE_SEARCH_PANEL';
export function toggleSearchPanel() {
    return {
        type: TOGGLE_SEARCH_PANEL,
        payload: {},
    };
}

const SET_IS_SEARCH_DIRTY = 'SET_IS_SEARCH_DIRTY';
export function setIsSearchDirty(isSearchDirty: boolean) {
    return {
        type: SET_IS_SEARCH_DIRTY,
        payload: {
            isSearchDirty,
        },
    };
}

const SET_OVERLAY_MODE_FOR_TASK = 'SET_VIEW_MODE_FOR_TASK';
export function setOverlayModeForTask(id: string, mode: FootprintOverlayMode) {
    return {
        type: SET_OVERLAY_MODE_FOR_TASK,
        payload: {
            id,
            mode,
        },
    };
}

const CLEAR_OVERLAY_MODES = 'CLEAR_OVERLAY_MODES';
export function clearOverlayModes() {
    return {
        type: CLEAR_OVERLAY_MODES,
    };
}

const ADD_CLOUD_OVERLAY_MAP = 'ADD_CLOUD_OVERLAY_MAP';
export function addCloudOverlayMap(file_id: string, cloud_overlay: string) {
    return {
        type: ADD_CLOUD_OVERLAY_MAP,
        payload: {
            file_id,
            cloud_overlay,
        },
    };
}

const CLEAR_CLOUD_OVERLAY_MAP = 'CLEAR_CLOUD_OVERLAY_MAP';
export function clearCloudOverlayMap() {
    return { type: CLEAR_CLOUD_OVERLAY_MAP };
}

const SET_FILTER_DATES = 'SET_FILTER_DATES';
export function setSearchFilterDates(start?: Date, end?: Date) {
    return {
        type: SET_FILTER_DATES,
        payload: { start, end },
    };
}

const SET_ENVI_HEADER_DISPLAY = 'SET_ENVI_HEADER_DISPLAY';
export function setEnviHeaderDisplay(headers: EnviHeaders) {
    return {
        type: SET_ENVI_HEADER_DISPLAY,
        payload: { headers },
    };
}

const SET_TIMELINE_SCRUBBER_VISIBLE = 'SET_TIMELINE_SCRUBBER_VISIBLE';
export function setTimelineScrubberVisible(timelineScrubberVisible: boolean) {
    return {
        type: SET_TIMELINE_SCRUBBER_VISIBLE,
        payload: { timelineScrubberVisible },
    };
}

/*
    Filter Methods
*/

function computeStartDate(startDate: NullableDate, timelineDate: NullableDate): Date | undefined {
    if (timelineDate === null) {
        return startDate ?? undefined;
    } else {
        return timelineDate;
    }
}

function computeEndDate(
    endDate: NullableDate,
    timelineDate: NullableDate,
    timelineMode: TimelineMode,
): Date | undefined {
    if (timelineDate === null) {
        return endDate ?? undefined;
    } else {
        switch (timelineMode) {
            case 'daily':
                return timelineDate;
            case 'weekly':
                return add(timelineDate, { weeks: 1 });
            case 'monthly':
                return add(timelineDate, { months: 1 });
            case 'yearly':
                return add(timelineDate, { years: 1 });
        }
    }
}

export function doSearchAsync() {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const { roi, roiName, startDate, endDate, platforms, timelineMode, timelineDate, cloudCover } =
            getState().data.search;

        // Compute the time periods
        const start = computeStartDate(startDate, timelineDate);
        const end = computeEndDate(endDate, timelineDate, timelineMode);
        const aoiFilter: any =
            roi !== undefined && roi !== null && (roi as OSKGeoJson).features.length > 0
                ? JSON.stringify((roi as OSKGeoJson).toAPIGeometry())
                : undefined;

        // Compute the aoi stuff
        const aoiSearchParams: any = {};
        if (roiName) {
            aoiSearchParams['aoiName'] = roiName;
        } else {
            aoiSearchParams['aoi'] = aoiFilter;
        }

        dispatch(setSearching(true));
        // @ts-ignore because the typescript-axios client expects a Geometry object for ROI but doesn't serialize it properly.
        SigmaAPI.listCaptures({
            program: getProgramId(),
            capturedAfter: start ? format(start, 'yyyy-MM-dd') : undefined,
            capturedBefore: end ? format(end, 'yyyy-MM-dd') : undefined,
            sensor: platforms,
            limit: 1000,
            ...(cloudCover < 100 && { cloudCoverMax: cloudCover }), // Only include cloud cover filter if it's been modified from the default value.
            ...aoiSearchParams,
        })
            .then((result) => {
                // Extract footprints
                const footprints: Array<FootprintEntry> = flatMap(result.data.results, (result) => ({
                    fileId: result.id,
                    taskId: result.task_id,
                    footprint: OSKGeoJson.fromAPIGeometry(result.footprint),
                }));

                dispatch(clearSearchError());
                dispatch(
                    updateSearchResults(
                        result.data.results,
                        Object.values(getState().data.cart.enqueued).map((di) => di?.taskId),
                    ),
                );
                dispatch(updateApiResultsLength(result.data.count ?? 0));

                // Beam footprints over to map store
                dispatch(setFootprints(footprints));
                dispatch(setIsSearchDirty(false));
            })
            .catch((ex) => {
                dispatch(setSearchError(ex.message));
            })
            .finally(() => {
                dispatch(setSearching(false));
            });
    };
}

export function doSearchAsyncForTaskId(taskId: string, enqueueAll?: boolean) {
    return (dispatch: AppDispatch) => {
        const program = getProgramId();
        dispatch(setSearching(true));
        SigmaAPI.listCaptures({ program, taskId: [taskId] })
            .then((result) => {
                const flat_footprints: Array<FootprintEntry> = flatMap(result.data.results, (result) => ({
                    fileId: result.id,
                    taskId: result.task_id,
                    footprint: OSKGeoJson.fromAPIGeometry(result.footprint),
                }));

                dispatch(clearSearchError());
                dispatch(updateSearchResults(result.data.results));
                dispatch(updateApiResultsLength(result.data.count ?? 0));

                dispatch(setFootprints(flat_footprints));
                dispatch(setIsSearchDirty(false));

                // TODO: Make sure we're doing everything for a single task that we're doing for a collection
                if (enqueueAll) {
                    const ids = result.data.results?.map((result) => result.id);
                    // @ts-ignore
                    dispatch(enqueueFilesById(ids ?? []));
                }

                const footprints = result.data.results?.map((capture) => capture.footprint);
                if (footprints) {
                    const fb = getBounds(footprints.map((footprint) => OSKGeoJson.fromAPIGeometry(footprint))).map(
                        (n) => [n[0], n[1]],
                    );

                    if (fb.length > 0) {
                        const bounds = new L.LatLngBounds(
                            new L.LatLng(fb[1][0], fb[1][1]),
                            new L.LatLng(fb[0][0], fb[0][1]),
                        );

                        const aoi = OSKGeoJson.fromLatLngBounds(bounds);
                        dispatch(setRoi(aoi, '', 'auto'));
                    }
                }
            })
            .finally(() => {
                dispatch(setSearching(false));
            });
    };
}

/* Reducer */
type SearchStateType = {
    errorMessage?: string;
    roi?: OSKGeoJson;
    roiName?: string;
    roiType?: AoiType;
    isErrored: boolean;
    isSearching: boolean;
    results?: Array<Capture>;
    resultMap: Record<string, Capture>;
    apiResultsLength?: number;
    fileIdToCollectMap?: Record<string, string>;
    /** An object containing collect to fileId mapping. The key is collect, the value is an array of fileIds */
    collectToFileIdList: Record<string, string[]>;
    platforms: Array<number>;
    startDate: NullableDate;
    endDate: NullableDate;
    timelineMode: TimelineMode;
    timelineDate: NullableDate;
    searchPanel: boolean;
    isSearchDirty?: boolean;
    cloudCover?: number;
    taskOverlayModes: Record<string, FootprintOverlayMode>;
    cloudOverlayMap: Record<string, string>;
    filterStartDate?: Date;
    filterEndDate?: Date;
    enviHeaderDisplay?: EnviHeaders;
    timelineScrubberVisible?: boolean;
    numTimelineScrubberBuckets: number;
};

export type FootprintOverlayMode = 'none' | 'clouds' | 'rgb';

const initialState: SearchStateType = {
    errorMessage: undefined,
    resultMap: {},
    fileIdToCollectMap: {},
    collectToFileIdList: {},
    roi: undefined,
    roiName: undefined,
    roiType: undefined,
    isErrored: false,
    isSearching: false,
    results: undefined,
    apiResultsLength: 0,
    platforms: [],
    startDate: null,
    endDate: null,
    timelineMode: 'daily',
    timelineDate: null,
    searchPanel: true,
    isSearchDirty: true,
    cloudCover: 100,
    taskOverlayModes: {},
    cloudOverlayMap: {},
    filterStartDate: undefined,
    filterEndDate: undefined,
    timelineScrubberVisible: false,
    numTimelineScrubberBuckets: 0,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ROI: {
            const { geoJson, aoiName, aoiType } = action.payload;
            return {
                ...state,
                roi: geoJson,
                roiName: aoiName,
                // Only set the type if there's a valid geoJson provided.
                // Otherwise clear the type.
                roiType: geoJson.features.length > 0 ? aoiType : undefined,
            };
        }

        case CLEAR_SEARCH_ERROR: {
            return {
                ...state,
                isErrored: false,
                errorMessage: undefined,
            };
        }

        case SET_SEARCH_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                isErrored: errorMessage !== undefined,
                errorMessage,
            };
        }

        case SET_SEARCHING: {
            const { isSearching } = action.payload;
            return {
                ...state,
                isSearching,
            };
        }

        case UPDATE_SEARCH_RESULT: {
            const { result, retainTaskIds } = action.payload;
            const fileIdToCollectMap: Record<string, string> = {};
            const collectToFileIdList: Record<string, string[]> = {};
            const resultMap: Record<string, Capture> = state.resultMap;

            // If we're given a list of taskIds to retain,
            // copy those entries forward
            Object.entries(state.collectToFileIdList)
                .filter((c) => retainTaskIds.includes(c[0]))
                .forEach((c) => {
                    collectToFileIdList[c[0]] = c[1];
                });

            result.forEach((result: Capture) => {
                fileIdToCollectMap[result.id] = result.task_id;
                collectToFileIdList[result.task_id] = collectToFileIdList[result.task_id] ?? [];
                collectToFileIdList[result.task_id].push(result.id);
                resultMap[result.id] = result;
            });

            const buckets = bucketByDateNoGaps(result);

            return {
                ...state,
                results: result,
                fileIdToCollectMap,
                collectToFileIdList,
                numTimelineScrubberBuckets: buckets.length,
                resultMap,
            };
        }

        case UPDATE_API_RESULTS_LENGTH: {
            const { length } = action.payload;

            return {
                ...state,
                apiResultsLength: length,
            };
        }

        case INCLUDE_PLATFORM: {
            const { platform }: any = action.payload;
            const nextPlatforms = [...state.platforms];
            if (!nextPlatforms.includes(platform)) {
                nextPlatforms.push(platform);
            }

            return {
                ...state,
                platforms: nextPlatforms,
            };
        }

        case SET_TIMELINE_DATE: {
            const { date }: any = action.payload;
            return {
                ...state,
                timelineDate: date,
            };
        }

        case SET_TIMELINE_MODE: {
            const { mode }: any = action.payload;
            return {
                ...state,
                timelineMode: mode,
            };
        }

        case SET_DATE_RANGE: {
            const { start, end }: any = action.payload;
            return {
                ...state,
                startDate: start,
                endDate: end,
            };
        }

        case SET_CLOUD_COVER_FILTER: {
            const { cloudCover }: any = action.payload;

            return {
                ...state,
                cloudCover,
            };
        }

        case EXCLUDE_PLATFORM: {
            const { platform } = action.payload;
            const nextPlatforms = [...state.platforms];
            if (nextPlatforms.includes(platform)) {
                nextPlatforms.splice(nextPlatforms.indexOf(platform), 1);
            }
            return {
                ...state,
                platforms: nextPlatforms,
            };
        }

        case TOGGLE_SEARCH_PANEL: {
            const { searchPanel } = state;
            return { ...state, searchPanel: !searchPanel };
        }

        case SET_IS_SEARCH_DIRTY: {
            const { isSearchDirty } = action.payload;
            return { ...state, isSearchDirty };
        }

        case SET_OVERLAY_MODE_FOR_TASK: {
            const { id, mode } = action.payload;

            const taskOverlayModes = state.taskOverlayModes;
            taskOverlayModes[id as string] = mode;

            return { ...state, taskOverlayModes };
        }

        case CLEAR_OVERLAY_MODES: {
            return { ...state, taskOverlayModes: {} };
        }

        case ADD_CLOUD_OVERLAY_MAP: {
            const { file_id, cloud_overlay } = action.payload;

            const newOverlayMap = state.cloudOverlayMap;
            newOverlayMap[file_id] = cloud_overlay;

            return {
                ...state,
                cloudOverlayMap: newOverlayMap,
            };
        }

        case CLEAR_CLOUD_OVERLAY_MAP: {
            return {
                ...state,
                cloudOverlayMap: {},
            };
        }

        case SET_FILTER_DATES: {
            const { start, end } = action.payload;
            return {
                ...state,
                filterStartDate: start,
                filterEndDate: end,
            };
        }

        case SET_ENVI_HEADER_DISPLAY: {
            const { headers } = action.payload;

            return {
                ...state,
                enviHeaderDisplay: headers,
            };
        }

        case SET_TIMELINE_SCRUBBER_VISIBLE: {
            const { timelineScrubberVisible } = action.payload;
            return {
                ...state,
                timelineScrubberVisible,
            };
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export const hasRoI = (state: RootState) => {
    return state.data.search.roi !== undefined && (state.data.search.roi as OSKGeoJson).toCoordinates().length > 0;
};

export const canSearch = (state: RootState) => {
    return (
        state.data.search.endDate !== null ||
        state.data.search.startDate !== null ||
        hasRoI(state) ||
        state.data.search.platforms.length > 0
    );
};

/* Selectors */
export const selectedSensors = (state: RootState) => {
    return filter(state.osk.sensors, (sensor: Sensor) => state.data.search.platforms.includes(sensor.osk_id));
};

function filterFilesByCaptureDateRange(
    files: Capture[],
    filterStartDate: Date | undefined,
    filterEndDate: Date | undefined,
) {
    return files.filter((file) => {
        if (file.acquisition_time && filterStartDate && filterEndDate) {
            const date = new Date(file.acquisition_time);
            return date.getTime() >= filterStartDate.getTime() && date.getTime() <= filterEndDate?.getTime();
        } else {
            return true;
        }
    });
}

export const getGroupedFiles = createSelector(
    (state: RootState) => state.data.search.results,
    (state: RootState) => state.data.search.startDate,
    (state: RootState) => state.data.search.endDate,
    (state: RootState) => state.data.search.platforms, // selected sensors
    (items: Capture[], startDate: Date, endDate: Date, platforms: number[]) => {
        return filterFilesByCaptureDateRange(items, startDate, endDate)
            .filter((item) => platforms.includes(item.sensor_id)) // Filter by selected sensor
            .reduce((groupedFiles: Record<string, Capture[]>, file: Capture) => {
                const { task_id } = file;
                if (groupedFiles.hasOwnProperty(task_id)) {
                    groupedFiles[task_id] = groupedFiles[task_id].concat(file);
                } else {
                    groupedFiles[task_id] = [file];
                }
                return groupedFiles;
            }, {});
    },
);

export const allTaskOverlayModes = (state: RootState) => {
    return Object.values(state.data.search.taskOverlayModes);
};

export const selectResults = createSelector([(state: RootState) => state.data.search.results], (results) => {
    return results;
});
