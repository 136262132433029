import { Box, OSKIcon, OSKThemeType, Pagination, Spinner, Typography } from 'oskcomponents';
import React, { useCallback, useEffect, useState } from 'react';
import ToolsNotationTable from '~/organisms/tables/ToolsNotationTable';
import { OSKToastView } from '~/molecules/OSKToastView';
import { SHORT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { useToggles } from '~/hooks/useToggles';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import { useTheme } from 'styled-components';
import DataNotationIncidentModal from '~/molecules/DataNotationIncidentModal';

// Stubbed in data type, this will come from api.ts in the future.
type IncidentNotation = {
    id: number;
    title: string;
    createdDate: string;
    startDate: string;
    endDate?: string;
    sensors: number[];
    notes?: string;
};

const ToolsDataNotationView = () => {
    const theme = useTheme() as OSKThemeType;
    const toggles = useToggles();
    const is_admin = toggles.router.isFeatureSet('osk_admin');

    const [notationPageNumber, setNotationPageNumber] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalData, setModalData] = useState<IncidentNotation | undefined>();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const getData = useCallback((): IncidentNotation[] => {
        return [
            {
                id: 20,
                title: 'Bad Pixel Map Error',
                createdDate: '2024-10-01T12:00:00Z',
                startDate: '2024-10-01T12:00:00Z',
                sensors: [7, 8],
            },
            {
                id: 21,
                title: 'Bore-sight Correction',
                createdDate: '2024-10-01T12:00:00Z',
                startDate: '2024-10-01T12:00:00Z',
                sensors: [7, 8, 9],
            },
            {
                id: 22,
                title: 'Corrupted Metadata',
                createdDate: '2024-10-04T12:00:00Z',
                startDate: '2024-10-04T12:00:00Z',
                endDate: '2024-10-04T12:00:00Z',
                sensors: [7, 9, 10],
            },
            {
                id: 25,
                title: 'Payload Error',
                createdDate: '2024-10-06T12:00:00Z',
                startDate: '2024-10-06T12:00:00Z',
                sensors: [7, 8, 9, 11],
            },
            {
                id: 31,
                title: 'Azimuth Correction',
                createdDate: '2024-10-10T12:00:00Z',
                startDate: '2024-10-10T12:00:00Z',
                endDate: '2024-10-17T12:00:00Z',
                sensors: [7, 8],
            },
        ];
    }, []);

    // Only admins can access the required internal apis,
    // let them know they need this permission if they don't have it
    if (!is_admin) {
        return (
            <OSKToastView>
                <FullscreenNotationContainer>
                    <Box style={{ width: '100%', height: '100%' }} center="all" col>
                        <OSKIcon code="generic-error" height={300} mb={-30} />
                        <Typography variant="heading2">Insufficient Permission</Typography>
                        <Typography
                            variant="body2"
                            style={{ marginTop: '8px', width: '400px', textAlign: 'center', lineHeight: '1.5' }}
                        >
                            Your account needs to be flagged as &apos;staff&apos; in order to access this utility. Post
                            in
                            <a
                                href="https://orbital-sidekick.slack.com/archives/C05R7GYJUUW"
                                target="_blank"
                                rel="noreferrer"
                            >
                                &nbsp;#sigma-feedback&nbsp;
                            </a>
                            on the OSK Slack to request access.
                        </Typography>
                    </Box>
                </FullscreenNotationContainer>
            </OSKToastView>
        );
    }

    return (
        <OSKToastView>
            <ActiveModeSwitcher />
            {modalData && (
                <DataNotationIncidentModal data={modalData} onClose={() => setModalData(undefined)} visible />
            )}
            <FullscreenNotationContainer>
                {isLoading ? (
                    <Box center="all" style={{ width: '100%', height: '200px' }}>
                        <Spinner variant="Box" size="Large" />
                    </Box>
                ) : (
                    <>
                        <Box
                            style={{ position: 'absolute', right: '48px' }}
                            center="vertical"
                            onClick={() => {
                                setModalData({} as IncidentNotation);
                            }}
                        >
                            <Box
                                style={{
                                    width: '28px',
                                    height: '28px',
                                    color: 'white',
                                    backgroundColor: theme.colors.accent,
                                    borderRadius: '5px',
                                    marginRight: '8px',
                                }}
                                center="all"
                            >
                                <OSKIcon code="plus" />
                            </Box>
                            <Typography variant="heading5" underline>
                                Report Incident
                            </Typography>
                        </Box>
                        <Box col>
                            <ToolsNotationTable
                                data={getData()}
                                onIncidentSelect={(incident: IncidentNotation) => setModalData(incident)}
                            />
                        </Box>
                        {getData() && getData().length > 1 && (
                            <Pagination
                                inverted
                                offset={notationPageNumber * SHORT_VISIBLE_PAGE_SIZE}
                                pageSize={SHORT_VISIBLE_PAGE_SIZE}
                                count={10}
                                onChange={(pageNumber) => {
                                    setNotationPageNumber(pageNumber);
                                }}
                            />
                        )}
                    </>
                )}
            </FullscreenNotationContainer>
        </OSKToastView>
    );
};

const FullscreenNotationContainer = ({ children }: any) => {
    return (
        <Box style={{ width: '100%', height: '100%' }}>
            <Box
                p={32}
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    overflowY: 'scroll',
                }}
                col
                grow
            >
                <Typography variant="heading2" style={{ marginBottom: '18px' }}>
                    Data Notation
                </Typography>
                {children}
            </Box>
        </Box>
    );
};

export type { IncidentNotation };
export default ToolsDataNotationView;
