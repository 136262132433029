import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    MultiSelect,
    OSKThemeType,
    TextInput,
    Typography,
} from 'oskcomponents';
import { noop, Sensor } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';
import { IncidentNotation } from '~/views/ToolsDataNotationView';

export type DataNotaionIncidentModalProps = {
    /** The IncidentNotation to display and modify  */
    data: IncidentNotation;
    /** A list of sensor metadata from redux */
    sensors?: Sensor[];
    /** Method to invoke when the close button is pressed */
    onClose: () => void;
} & ModalProps;

export const DataNotationIncidentModal = ({ data, sensors, onClose, ...props }: DataNotaionIncidentModalProps) => {
    const theme = useTheme() as OSKThemeType;

    if (!data) {
        if (props.visible) {
            onClose();
            return <React.Fragment />;
        } else return <React.Fragment />;
    }

    return (
        <Modal w={462} {...props}>
            <ModalHeader variant="primary" style={{ backgroundColor: theme.colors.black900 }}></ModalHeader>
            <ModalBody style={{ backgroundColor: theme.colors.black900, color: theme.colors.white }}>
                <Box col>
                    <Box col>
                        <Typography variant="body3">Incident ID</Typography>
                        <Typography variant="body3">
                            <TextInput name="incident-id"></TextInput>
                        </Typography>
                    </Box>

                    <Box>
                        <Box col>
                            <Typography variant="body3">Start Date</Typography>
                            <Typography variant="body3">
                                <TextInput name="incident-start-date"></TextInput>
                            </Typography>
                        </Box>
                        <Box col>
                            <Typography variant="body3">End Date</Typography>
                            <Typography variant="body3">
                                <TextInput name="incident-end-date"></TextInput>
                            </Typography>
                        </Box>
                    </Box>
                    <Box col>
                        <Typography variant="body3">Sensors Affected</Typography>
                        <Typography variant="body3">
                            <MultiSelect
                                items={
                                    sensors?.map((sensor) => ({
                                        label: sensor.osk_sensor_name,
                                        value: sensor.osk_id,
                                    })) ?? []
                                }
                                defaultValues={data.sensors}
                            />
                        </Typography>
                    </Box>
                    <Box col>
                        <Typography variant="body3">Excluded Task IDs</Typography>
                        <Typography variant="body3">
                            <TextInput name="incident-excluded-tasks" multiline></TextInput>
                        </Typography>
                    </Box>
                    <Box col>
                        <Typography variant="body3">Notes</Typography>
                        <Typography variant="body3">
                            <TextInput name="incident-notes" multiline></TextInput>
                        </Typography>
                    </Box>
                </Box>
            </ModalBody>
            <ModalFooter style={{ backgroundColor: theme.colors.black900, borderTop: 'none' }}>
                <Button
                    onClick={onClose}
                    label="Close"
                    style={{ border: `1px solid ${theme.colors.lightGray}`, fontSize: '1rem' }}
                />
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    variant="action"
                    style={{ fontSize: '1rem' }}
                    label="Save"
                />
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    const { sensors } = state.osk;

    return {
        sensors,
    };
};

export default connect(mapStateToProps, noop)(DataNotationIncidentModal);
