import { Box, BoxProps, OSKIcon, OSKIconType, OSKThemeType, Typography } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { CollapsableGroup } from '~/atoms';

export type CaptureGroupIcon = 'all' | 'some' | 'none' | 'hidden';

export type CaptureGroupProps = {
    /** Children elements to render inside this group */
    children?: React.ReactNode;
    /** The taskId (used for the title) */
    taskId: string;
    /** An optional bit of text that can be shown underneath the task id */
    caption?: string;
    /** The icon type to show. all | some | none */
    selectedIcon: CaptureGroupIcon;
    /** An optional override for the icon we display in the capture group. */
    icon?: OSKIconType;
    /** Method to invoke when the icon is clicked */
    onIconClick?: () => void;
    /** Method that's invoked when the expand status is changing */
    onExpandChange?: (isExpanded: boolean) => void;
    /** A method that's called before expansion is finalized.
     * Returns a promise that resolves when the operation is complete. */
    beforeExpand?: () => Promise<void>;
    /** If true, the group will start expanded */
    defaultExpanded?: boolean;
} & Omit<BoxProps, 'ref'>;

export const CaptureGroup = ({
    children,
    icon,
    selectedIcon,
    onIconClick,
    onExpandChange,
    beforeExpand,
    defaultExpanded,
    taskId,
    caption,
    style,
    ...props
}: CaptureGroupProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <CollapsableGroup
            style={{
                borderRadius: '4px',
                border: `1px solid ${theme.colors.black200}`,
                backgroundColor: theme.colors.white,
                boxShadow: '0px 4px 4px 0px #00000040',
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '100%',
                minWidth: 0,
                ...style,
            }}
            onExpandChange={onExpandChange}
            beforeExpand={beforeExpand}
            defaultExpanded={defaultExpanded}
            title={
                <Box
                    style={{
                        padding: '14px 0px',
                        flex: '0 1 auto',
                        minWidth: 0,
                    }}
                >
                    {selectedIcon !== 'hidden' ? (
                        <Box
                            center="all"
                            w={40}
                            p={12}
                            onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                onIconClick && onIconClick();
                            }}
                        >
                            <OSKIcon
                                fill={theme.colors.black600}
                                code={
                                    selectedIcon === 'all'
                                        ? 'check-square'
                                        : selectedIcon === 'some'
                                        ? 'dash-square'
                                        : 'reg-square'
                                }
                                scale={150}
                                cursor="pointer"
                            />
                        </Box>
                    ) : null}
                    <Box
                        center="vertical"
                        style={{
                            paddingLeft: selectedIcon !== 'hidden' ? '2px' : '8px',
                            minWidth: 0,
                            flex: '0 1 auto',
                        }}
                    >
                        <OSKIcon code={icon ?? 'footprint'} strokeWidth={1} width={40} />
                        <Box
                            style={{
                                minWidth: 0,
                                textOverflow: 'ellipsis',
                                paddingLeft: '12px',
                            }}
                        >
                            <Typography
                                variant="body3"
                                strong
                                style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    minWidth: 0,
                                    whiteSpace: 'nowrap',
                                    marginRight: '8px',
                                }}
                                title={taskId}
                            >
                                {taskId}
                            </Typography>
                            {caption && <Typography variant="caption1">{caption}</Typography>}
                        </Box>
                    </Box>
                </Box>
            }
            {...props}
        >
            {children}
        </CollapsableGroup>
    );
};
