import React, { useEffect, useRef, useState } from 'react';
import { Box, OSKThemeType, Spinner } from 'oskcomponents';
import { Capture, Sensor, noop } from 'oskcore';
import { connect } from 'react-redux';
import { SigmaOrderDetails } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import { CaptureGroup, CaptureItem } from '~/molecules';
import { useTheme } from 'styled-components';

export type CollectsTabProps = {
    /** Whether the tab is loading or not */
    isLoading: boolean;
    /** The currently active order to render */
    order?: SigmaOrderDetails;
    /** The sensor list */
    sensors: Sensor[];
};

export const CollectsTab = ({ isLoading, sensors, order }: CollectsTabProps) => {
    const [collects, setCollects] = useState<string[]>([]);
    const groupedCollects = useRef<Record<string, Capture[]>>({});
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        if (order) {
            const byCollect = (order.captures ?? []).reduce((acc, cur) => {
                acc[cur.task_id] = acc[cur.task_id] ?? [];
                acc[cur.task_id].push(cur);
                return acc;
            }, {} as Record<string, Array<Capture>>);

            groupedCollects.current = byCollect;
            setCollects(Object.keys(byCollect));
        }
    }, [order]);

    if (isLoading) {
        return (
            <Box style={{ width: '100%', height: '100%' }} grow center="all">
                <Spinner variant="Box" size="Large" />
            </Box>
        );
    } else if (order) {
        return (
            <Box style={{ width: '100%', padding: '20px' }} grow col>
                {collects.length === 0 && (
                    <Box grow center="all">
                        No collects for this order yet.
                    </Box>
                )}
                {collects.map((taskId) => {
                    const activeGroup = groupedCollects.current[taskId] ?? [];

                    return (
                        <CaptureGroup
                            selectedIcon="hidden"
                            defaultExpanded={true}
                            key={`collect_${taskId}`}
                            mb={20}
                            taskId={taskId}
                        >
                            {activeGroup.map((x) => (
                                <CaptureItem
                                    intentOnly={true}
                                    fileId={x.id}
                                    hideIcon={true}
                                    key={`collect_item_${x.id}`}
                                    data={x}
                                    sensors={sensors}
                                    style={{
                                        margin: '0px 0px',
                                        backgroundColor: theme.colors.white,
                                    }}
                                />
                            ))}
                        </CaptureGroup>
                    );
                })}
            </Box>
        );
    } else {
        return <></>;
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.data.orders.loadingSingleOrder,
        order: state.data.orders.activeOrder,
        sensors: state.osk.sensors,
    };
};

export default connect(mapStateToProps, noop)(CollectsTab);
